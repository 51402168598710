.whatsapp-container{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  background: #ECE5DD;
}

.whatsapp-item{
  margin:5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.whatsapp-avatar{
  width:30px;
  height: 30px;
  border-radius:50%;
  font-size: small;
  font-weight: bold;
  border: 2px solid black;
  margin:10px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

}

.whatsapp-avatar-img{
  width:40px;
  height: 40px;
  border-radius: 20px;
}

.whatsapp-title{
  font-weight: bold;
  color: black;
}

.whatsapp-input{
  width: 250px !important;
  border: 1px solid black !important;
  background-color: white;
  border-radius:40px !important;
  margin: 2px 16px;
  padding: 10px 18px;
  color:black;
  height: 44px;
  font-size: 14px;
  font-weight: bold;

}

.whatsapp-input-tel{
  width: 200px !important;
  border: 1px solid black !important;
  background-color: white;
  border-radius:20px !important;
  margin: 2px;
  padding: 10px;
  color:black;
  height: 30px;
  font-size: 12px;
  font-weight: bold;

}

.button-whatsapp {
  width: 150px;
  height: 44px;
  background: #063EF9;
  border-radius: 40px;
  border: none;
  margin: 0px 16px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  &:disabled {
    opacity: 0.5;
  }
}

.button-whatsapp-tel {
  width: 90px;
  height: 30px;
  background: #063EF9;
  border-radius: 20px;
  border: none;
  margin: 0px 5px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}