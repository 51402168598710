
.btn-secondary {

  height: 40px;
  border-radius: 20px;

  margin: 5px;

  padding: 0 13px;

  border: none;
  background-color: #44c5f3;

  transition: all 0.3s;
  color: white;

  &:hover {
    background-color: lighten(#44c5f3, 8%);
  }

  &:disabled {
    background-color: lighten(#44c5f3, 8%);
    opacity: 0.5;
  }

  &--red {

    background-color: #f52727;

    &:hover {
      background-color: lighten(#f52727, 8%);
    }

    &:disabled {
      background-color: lighten(#f52727, 8%);
      opacity: 0.5;
    }
  }

  &--small {
    font-size: 0.9rem;
  }

  &--space-left {
    margin-left: 20px;
  }
}