.inline--left {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  @include sm-only {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
  }
}

.regular-space {
  margin-top: 60px;
  margin-bottom: 60px;
}

.space-5 {
  margin: 5px;
}

.space-light {
  margin: 7px;
}

.space-medium {
  margin: 12px;
}

.space-margin--small {
  margin: 10px;
}

.space-right {
  margin-right: 20px !important;
}

.space-right2x {
  margin-right: 35px;
}

.space-left--small {
  margin-left: 10px;
}

.space-left {
  margin-left: 20px;
}

.space-top {
  margin-top: 12px;
  margin-bottom: 10px;
}

.space-flex {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.space-flex--left {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.space100 {
  width: 100%;
}

.space-flex--right {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.space-flex--left {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.space-flex--center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  &--wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}

.space-flex--center-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.space-flex--space-between {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.space-flex--space-evenly {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.space-flex--column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.space-flex--bar {

}

.fullw {
  width: 100%;
}

.space-bottom {
  margin-bottom: 11px;
}

.padding-light {
  padding: 15px;
}

.img-steps {
  width: 100%;
}

.space--grey {
  background-color: $grey;
  padding: 30px;
  border-radius: 6px;
}

.space-padding--small {
  padding: 3px 12px;
}

.space-relative-bottom {
  position: relative;
  top: 6px;
}

.space-small-text-margin {
  margin-top: 18px;
  margin-bottom: 15px;
}