.ambassador-dashboard--header{
  display:flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.ambassador-dashboard--solicitations{
  display:flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

}
.ambassador-dashboard--solicitation{
  display:flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  width:700px;
  margin:10px;
}
.ambassador-dashboard--solicitation-left{
  margin-left: 20px;
  display:flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.ambassador-dashboard--solicitation-item{
  margin:3px;
}
.ambassador-dashboard--business{
  display:flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  width:300px;
  margin:10px;
}

.ambassador-dashboard--business-avatar{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 6px;
}

.ambassador-dashboard--stats{
  display:flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position:relative;
  top:-10px;
  left:-230px;
}

.ambassador-dashboard--stats-item{
  color: #6d6d6f;
  font-size: x-small;
  margin:2px;
}

.ambassador-dashboard--title{
  font-weight: bold;
}