.hide-on-smartphones {
  @include sm-only {
    display: none;
  }
}

.hide-on-computers {
  @include md-only {
    display: none;
  }
  @include from-lg {
    display: none;
  }
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}