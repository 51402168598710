
.shortlist {

  width: 90%;
  //max-width: 650px;
  margin: auto;

  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;

  &__item {
    width: 100%;
    max-width: 700px;
    padding: 20px;
    margin: 20px 0;

    background-color: white;

    @include sm-only {
      margin: 15px 0;
    }

    overflow: hidden;

    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.29);
    border-radius: 10px;

    &__line1, &__line2 {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

    }

    &__line1 {
      margin-bottom: 30px;
      align-items: flex-start;
    }

    &__line2 {
      align-items: center;
    }

  }

}

.shortlist__header {
  background-color: $grey;
  color: $blackish8;

  overflow: hidden;

  margin: 40px 0 0 0;
  padding: 60px 0 60px 0;

}

.shortlist-stats {

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;

  width: 95%;
  max-width: 800px;
  margin: auto;

  @include sm-only {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  & button {

    //flex-grow: 1;
    min-width: 100px;
    overflow: hidden;

    background-color: lighten($dominant1, 20%);
    padding: 15px 20px;
    margin: 10px;

    border-radius: 6px;

    & .stats-block__title {
      color: white;
      text-align: center;
      font-size: 0.98rem;
      font-family: 'geomanistblack', 'Lato', sans-serif;
      margin-bottom: 12px;
    }
    & .stats-block__number {
      color: white;
      text-align: center;
      font-size: 1.8rem;
      //font-family: 'geomanistblack', 'Lato', sans-serif;
      font-weight: bolder;
    }

    &:active {
      position: relative;
      top: 2px;
    }
  }
}

.stats--white {
  border-top: 9px solid $grey !important;
}

.stats--black {
  border-top: 9px solid $blackish7 !important;
}

.stats--green {
  border-top: 9px solid desaturate(lighten($green, 10%), 5%) !important;
}

.stats--orange {
  border-top: 9px solid desaturate(lighten(#ff5f3b, 10%), 5%) !important;
}

.stats--red {
  border-top: 9px solid desaturate(lighten($redpink, 10%), 5%) !important;
}

.stats--gold {
  border-top: 9px solid desaturate(lighten($yellow, 10%), 5%) !important;
}

.shortlist__subheader {
  background-color: lighten($dominant1, 40%);
  padding: 30px 0;
}


.shortlist__item {
  &--blocked {
    border-left: 9px solid $blackish7 !important;
    border-right: 9px solid $blackish7 !important;
  }
  &--range1 {
    border-left: 9px solid desaturate(lighten($redpink, 10%), 5%)!important;
    border-right: 9px solid desaturate(lighten($redpink, 10%), 5%)!important;
  }
  &--range2 {
    border-left: 9px solid desaturate(lighten(#ff5f3b, 10%), 5%) !important;
    border-right: 9px solid desaturate(lighten(#ff5f3b, 10%), 5%) !important;
  }
  &--range3 {
    border-left: 9px solid desaturate(lighten($green, 10%), 5%) !important;
    border-right: 9px solid desaturate(lighten($green, 10%), 5%) !important;
  }
  &--range4 {
    border-left: 9px solid desaturate(lighten($yellow, 10%), 5%) !important;
    border-right: 9px solid desaturate(lighten($yellow, 10%), 5%) !important;
  }
}

.wheel-container {

  position: relative;

  width: 70px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  margin: 0 5px;

  &__title {
    font-size: 0.8rem;
    text-align: center;
  }
  &__value {
    width: 56px;
    height: 56px;
    position: relative;
    font-family: 'geomanistblack', 'Lato', sans-serif;
    font-size: 0.9rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  &__value2 {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    font-size: 1.2rem;
  }
}

.wheel {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 8px solid $grey;
  transform: rotateZ(45deg);

  &--result25 {
    border-top: 8px solid $green;
  }

  &--result50 {
    border-top: 8px solid $green;
    border-right: 8px solid $green;
  }

  &--result75 {
    border-top: 8px solid $green;
    border-right: 8px solid $green;
    border-bottom: 8px solid $green;
  }

  &--result100 {
    border: 8px solid $green;
  }
}

.candidacy-interested-status {
  font-size: 0.8rem;
}