.profile2 {

  padding: 20px;
  background-color: #fff6f0;

  &__block {
    background-color: white;
    border-radius: 8px;
    margin: 20px 10px;
    padding: 20px;

    &__title {

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 10px;

      & img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      & span {
        color: #1f50cc;
        font-weight: bolder;
        letter-spacing: 0.8px;
      }
    }

    &__input-area {
      width: 100% !important;
      min-height: 130px !important;
      background: transparent !important;
      border: none !important;
      color: #333 !important;
      text-align: left !important;
    }

    &__input {
      width: 80px !important;
      height: 80px !important;
      background: transparent !important;
    }
  }
}

.profile2__list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  &__element {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    background-color: #b7dbff;
    border-radius: 20px;
    padding: 0px 12px;
    margin: 5px;
    font-size: 0.9rem;
  }
}

.profile2-btn-add {
  background: transparent;
  border: none;
  padding: 1px;
  margin: 3px;
  font-size:1.3rem;
  font-weight: bolder;
  color: #666;

  &--small {
    font-size:0.8rem;
  }

  &:disabled {
    opacity: 0.3;
  }
}

.profile2__newJob {
  padding: 30px;
  //background-color: #eee;
  //border-radius: 10px;

  font-size: 0.88rem;

  padding-left: 20px;
  border-left: 3px solid #cc1f1f;

  & * {
    font-size: 0.88rem;
  }

  & li {
    list-style: inside;
  }

  &__line {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

.profile2__input {
  border: none !important;
}

.profile2__input--full {
  border: none !important;
  width: 100% !important;
  border-bottom: 1px solid #999 !important;


}

.profile2__input--full-mission {
  width: 80% !important;
  border: 1px solid #999 !important;
  height:30px;
  font-size: 0.88rem !important;
  padding: 5px 10px !important;
  border-radius: 20px !important;
}

.profile2__mini-title {
  font-weight: bolder;
  &--box {
    //display: inline;
    width: 160px;
  }
}

.profile2__big-title {
  font-weight: bolder;
  font-size: 1rem;
  &--box {
    //display: inline;
    width: 160px;
  }
}

.profile2-experience {

  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 0.88rem;

  padding-left: 20px;
  border-left: 3px solid #1f50cc;

  & * {
    font-size: 0.88rem;
  }

  &__position-bloc {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__position {
    color: #1f50cc;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__company {
    color: #1f50cc;
    font-size: 0.9rem;
  }

  &__description {
    color: #777;
  }

  &__missions {
    margin: 17px 0;

    &__title {
      margin-bottom: 12px;
      text-decoration: underline;

    }

    & ul {
      //margin-left: 20px;
    }

    & li {
      list-style: inside;
    }
  }
}

.profile2-warning {
  color: #e20000;
  text-align: center;
  font-size: 0.88rem;
}

.input--left {
  text-align: left !important;
}

.all-text--small {
  font-size: 0.88rem !important;
  & * {
    font-size: 0.88rem !important;
  }
}

.profile2-date {
  font-size: 0.88rem !important;
  padding:5px 10px !important;
  border-radius: 20px !important;
  margin-left: 20px !important;
}

.profile2-date-color {
  font-size: 0.88rem !important;
  padding:5px 10px !important;
  border-radius: 20px !important;
  margin-left: 20px !important;
  background-color: #f6f2ed !important;
}