.section-navigation {

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  max-width: 1100px;
  margin: auto;

  &__button {

    flex: 1;

    margin: 0 10px;

    height: 90px;

    background-color: $colors-V2-layout-grey;

    border-radius: 8px;

    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.19);

    transition: all 0.3s;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    text-align: center;

    &--white {
      background-color: white;
    }

    &:hover{
      cursor: pointer;
    }

    &--selected {
      background-color: #6ad1f5;
    }

  }
}