.multiposting-section {

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  max-height: 400px;
  overflow-y: scroll;

  &__app {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
    width: 90%;

    &__left {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    &__right {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
    }

    & button {
      float: right;
    }

  }

  & img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

}