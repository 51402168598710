
/**
* Module defining colors and style vars
*/

//$colors-V2-layout-grey: #f8f8f8;
//$colors-V2-layout-grey: #f1f1f1;
$colors-V2-layout-grey: #f6f2ed;


$colors-V2-dominant1: #96e2f8;
$colors-V2-blue2: #44c5f3;
$colors-V2-yellow: hsla(44, 100%, 59%, 1);

// Palette
$red: #e61a3b;
$redpink: #E21C64;
$yellow: hsla(44, 100%, 59%, 1);
$cyan: #3dbed0;
$white: #FFFFFF;
$green: #41e39e;
$violet: #4573E8;
$black: #000000;
$pink: #fa728e;
//$grey: #f1f1f1;
$grey: #f6f6f6;
$whiteish: #fefefe;
$blackish1: #0a0a0a;
$blackish2: #121212;
$blackish3: #191919;
$blackish4: #2b2525;
$blackish5: #282828;
$blackish6: #343434;
$blackish7: #414660;
$blackish8: #404253;
$link-blue: #007aaa;
$linkedin: hsla(201, 100%, 35%, 1);
$facebook: #3B5998;
$twitter: #1DA1F2;
$indeed: #1a0dab;
$cadreemploi: #26aae1;
$monster: #642891;
$regionsjob: #334b78;



$test1: #3a76ac;

// Assignments of the palette

//$dominant1: $blackish3;
$dominant1: $test1;
$dominant1text: white;
$dominant2: $cyan;
$dominant2text: white;
$button-text: $white;
$font-color: $blackish8;
$navbar-underline: $pink;

$newDominant1: #1DA1F2;

$background-window-color: rgba(47, 47, 47, 0.51);
//$background-window-color: rgba(227, 230, 234, 0.75);


// default-font-size and default-line-size: see reset.scss
// Break-points: see responsiveness.scss
