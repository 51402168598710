
.splash-v3 {

  text-align: center;

  &__title {
    font-family: 'geomanistbold', 'Lato', sans-serif;
    font-size: 2.5rem;
    text-align: center;

    @include sm-only {
      font-size: 2rem;
    }
  }

  &__title2 {
    font-family: 'geomanistbold', 'Lato', sans-serif;
    font-size: 1.5rem;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
  }

  &__main-frame {
    width: 95%;
    max-width: 1400px;
    //margin: 30px auto;
  }

  &__button {
    border: none;
    padding: 13px 20px;
    background-color: $colors-V2-blue2;
    border-radius: 30px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  &__adopters {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin: 30px auto;
    & img {
      width: 90px;
      margin: 20px;
    }
  }

}

.red {
  //background-color: salmon;
}

.lp3-feedback {

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  @include md-only {
    flex-flow: row wrap;
  }

  @include sm-only {
    flex-flow: column nowrap;
  }
}

.lp3-multiposting {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 900px;
  margin: auto;

  & img {
    width: 65px;
    height: 65px;
    margin: 20px 40px;
  }
}

.lp3__navbar {

  height: 70px;
  width: 95%;
  max-width: 1300px;
  margin: auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  background-color: white;

  @include sm-only {
    height: 65px;
  }

  &__logo {

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    &__img {
      width: 65px;
      height: 65px;

      @include sm-only {
        width: 55px;
        height: 55px;
      }
    }

    &__title {
      font-family: 'geomanistbold', 'Lato', sans-serif;
      font-size: 1.5rem;
      @include sm-only {
        font-size: 1.2rem;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }
}

.magic-font {
  font-size: 7rem;
  font-family: "geomanistbold", sans-serif;
  background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-bold {
  font-family: "geomanistbold", sans-serif;
  font-size: 1.2rem;
  margin-left: 20px;
}