.app-window {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;


  &__app-name {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: center;

    &__content {
      padding: 20px;
    }

    &__slider {
      width: 480px;


    }

    & img {
      @include sm-only {
        width: 60px;
      }
      align-self: center;
      height: auto;
      width: 70px;
      padding-top: 5px;
    }

    & h1 {
      @include sm-only {
        font-size: 1rem;
      }
      font-family: 'Lato', sans-serif;
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
      color: black;
    }

    & title {
      font-family: sofia-pro, Roboto, Helvetica, Arial, sans-serif;
      display: flex;
      flex-flow: column nowrap;
      justify-self: flex-start;
      align-items: center;
      margin: 30px auto;
      font-size: 1.2rem;
      font-weight: bold;
      color: black;


      & article {

        margin: 15px 8px;
        align-self: center;

      }

    }

    .switch {

      width: 55px;
      height: 26px;

      position: relative;
      display: inline-block;

    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
    }

    .slider:before {

      height: 22px;
      width: 22px;
      bottom: 2px;

      position: absolute;
      content: "";
      left: 4px;
      background-color: white;
    }

    input:checked + .slider {
      background-color: limegreen;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px limegreen;
    }

    input:checked + .slider:before {

      -webkit-transform: translateX(24px);
      -ms-transform: translateX(24px);
      transform: translateX(24px);


    }

    /* Rounded sliders */
    .slider.round {

      border-radius: 26px;
    }

    .slider.round:before {
      border-radius: 50%;
    }


  }
}