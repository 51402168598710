
.btn-page {
  background: transparent;
  border: none;
  margin: 5px;
  font-size: 1.3rem;

  transition: all 0.3s;

  color: #555;

  &--selected {
    font-weight: bolder;
    //font-size: 1.4rem;
    color: black;
  }
}

.previous-btn{
  border:none;
  background: transparent;
  position: relative;
  top:-3px;
}