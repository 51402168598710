.mass-action__btn {

  height: 30px;
  border-radius: 20px;
  margin: 5px;
  padding: 0 10px;
  border: none;
  transition: all 0.3s;
  color: white;
  background-color: lighten(#44c5f3, 8%);
  opacity: 0.5;

  &--selected {
    opacity: 1;
  }
}
.mass-action__center {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

}