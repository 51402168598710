.business-page-jobs {

  &__container {

    width: 95%;
    max-width: 1000px;

    margin: auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    opacity: 0;
    position: relative;
    animation: assistant-appear 0.3s forwards;

    &__job {
      background-color: #eee;
      border-radius: 20px;
      width: 100%;
      max-width: 700px;
      margin: 15px auto;
      padding: 12px 24px;
      position: relative;

      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;

      &__left {
      }

      &__right {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      }
    }

    &--column {

      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;

      & > * {
        width: 100%;
      }
    }

    & button {

      background: $yellow;
      border: none;
      padding: 10px 18px;
      //min-height: 60px;
      border-radius: 30px;
      transition: all 0.2s;

      margin: 5px;

      &:hover {
        background: lighten($yellow, 20%);
      }

      &:disabled {
        opacity: 0.5;
      }

      flex-grow: 1;

    }
  }
}
.job__small-title {
  font-size: 0.8rem;
  font-weight: bolder;
  color: black;
}

.job__position {
  font-size: 1.1rem;
  color: #0d8cda;
  font-weight: bolder;
}

.job__contract {
  padding: 5px 10px;
  background-color: #0d8cda;
  color: white;
  font-weight: bolder;
  border-radius: 20px;
  font-size: 0.8rem;
}

.job__date {
  font-size: 0.9rem;
  color: #666;
}

.job__location {
  font-size: 0.9rem;
  color: #666;
}

.job__description {
  margin-top: 6px;
  margin-bottom: 6px;
}