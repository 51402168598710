.dashboard--title{
  font-weight: bolder;
  font-size: large;
  background-color:rgb(6, 62, 249);
  color: #ffffff;
  text-align: left;
  border:none;
  border-top: 1px solid rgb(6, 62, 249);
  border-right: none;
  box-shadow: 0 2px 3px 0 rgba(6, 62, 249, 0.8) !important;
  z-index: 100000;
}

.dashboard-button-delete{
  height: 30px;
  width: 30px;
  margin: 5px;
}

.background-red {
  color:red;
  font-weight: bolder;
  border: none;
  border-bottom: 2px solid rgb(6, 62, 249);
  vertical-align: middle;
}

.background-orange {
  color:#ff8300;
  font-weight: bolder;
  border: none;
  border-bottom: 2px solid rgb(6, 62, 249);
  vertical-align: middle;
}

.background-green{
  color:#0bbe0b;
  font-weight: bolder;
  border: none;
  border-bottom: 2px solid rgb(6, 62, 249);
  vertical-align: middle;
}

.row{
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
}

.dashboard--ecl{
  table-layout:fixed !important;
  min-width: 220px !important;
  border: none;
}
.dashboard--ecl-title{
  table-layout:fixed !important;
  min-width: 220px !important;
  font-weight: bolder;
  font-size: large;
  background-color: rgb(6, 62, 249);
  color: #ffffff;
  text-align: left;
  border:none;
  border-top: 1px solid rgb(6, 62, 249);
  border-right: none;
  box-shadow: 0 2px 3px 0 rgba(6, 62, 249, 0.8) !important;
}



.dashboard--pipe-title{
  box-shadow: 0 2px 3px 0 rgba(68, 197, 243, 0.8);
  table-layout:fixed !important;
  min-width: 150px !important;
  font-weight: bolder;
  font-size: large;
  background-color: rgb(6, 62, 249);
  color: #ffffff;
  text-align: left;
  border:none;

}

.dashboard--input{
  border:1px solid black!important;
  border-radius: 5px;
  min-width: 210px !important;
  height: 30px;
  padding: 2px;
}

.dashboard--input--small{
  border:1px solid black!important;
  border-radius: 5px;
  width: 55px !important;
  height: 30px;
  padding: 2px;
}

.dashboard--select{
  border:1px solid black!important;
  border-radius: 5px;
  width: 120px !important;
  height: 30px;
  padding: 2px;
}


.dashboard--input-date{
  border: 1px solid black!important;
  width: 170px !important;
}

.dashboard--styled-table{
  border-collapse: collapse;
  margin: 5px;
  font-size: 0.9em;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}


.dashboard--styled-table th,
.dashboard--styled-table td {
  padding: 12px 25px !important;
}

.dashboard--case {
  border: none;
  border-bottom: 2px solid rgb(6, 62, 249);
  border-right: none;
  vertical-align: middle;
}

.call--case {
  border: none;
  vertical-align: middle;
}

.grey-background{
  background-color: rgba(6, 62, 249, 0.1);
}

.border-right{
  border-right: 2px solid rgb(6, 62, 249);
}

.border-left{
  border-left: 2px solid rgb(6, 62, 249);
}

.dashboard-big-title{
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;

}

.dashboard-big-title-item{
  color:rgb(6, 62, 249);
  font-size: x-large;
  font-weight: bolder;
}

.dashboard--subnav {
  text-align: center;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #063EF9;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 7px 14px 0 rgb(8 15 52 / 3%);
  width:50%;
  padding:20px;
  margin-left: 25%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
}

.dashboard--filter{
  border:1px solid #063EF9;
  border-radius: 10px;
  color: #063EF9;
  //padding-right: 2px;
  text-align: left;
}

.caption-red {
  color:red;
  font-style: italic;
  border: none;
  font-weight: bold;
  margin-right: 50px;

}

.caption-orange {
  color:#ff8300;
  font-style: italic;
  border: none;
  font-weight: bold;
  margin-right: 50px;
}

.caption-green{
  color:#0bbe0b;
  font-style: italic;
  border: none;
  font-weight: bold;
  margin-right: 50px;
}

.caption{
  display: flex;
  flex-flow: row;
  justify-content: center;

}

.caption-and-title{
  background-color: rgba(6, 62, 249, 0.1);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 35px;
  margin-right:5px;
  margin-left: 5px;
  border: 1px solid #063EF9;

}
.caption-title{
  text-align: center;
  margin-bottom: 10px;
  font-weight: bolder;
  font-style: italic;
  color: #063EF9;
  font-size: x-large;
}

.call-red {
  color:red;
  font-weight: bolder;
  border: none;
  vertical-align: middle;
}

.call-orange {
  color:#ff8300;
  font-weight: bolder;
  border: none;
  vertical-align: middle;
}

.call-green{
  color:#0bbe0b;
  font-weight: bolder;
  border: none;
  vertical-align: middle;
}

.call-background{
  border-bottom: 2px solid rgb(6, 62, 249);
  background-color: rgba(6, 62, 249, 0.1);
  vertical-align: middle;
  border-left: none;
  border-right: none;
  border-top: none;
}

.cve-background{
  border-bottom: 2px solid rgb(6, 62, 249);
  vertical-align: middle;
  border-left: none;
  border-right: none;
  border-top: none;
}

.btn--today{
  background-color: transparent;
  border-radius: 10px;
  border-color: rgb(6, 62, 249);
  color: rgb(6, 62, 249);
  margin-top: 4px;
}

.modern-link-for-today {
  text-decoration: underline;
  color: #0bbe0b;
  transition: color 0.3s;
  &:hover {
    color: #0bbe0b;
  }
}



