
.kanban {

  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  &__column {

    position: relative;

    min-width: 280px;
    max-width: 340px;

    min-height: 620px;
    flex-grow: 1;
    border-radius: 20px;


    margin: 3px;
    padding: 25px 10px;

    background-color: white;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;

    &__header {

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 4px;
      overflow: hidden;
      //background-color: coral;
      height: 122px;

      border-bottom: 1px solid #eee;

      &__title {
        font-family: 'geomanistbold', 'Lato', sans-serif;
        font-size: 1.05rem;
        color: $blackish6;
        //margin-bottom: 45px;
        overflow: hidden;
        margin-bottom: 8px;
        white-space: nowrap;
      }

      &__buttons {
        height: 30px;
        overflow: hidden;

        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        margin-bottom: 30px;

        &__btn {
          margin: 3px;
          //background-color: #bbb;
          background-color: white;
          opacity: 0.3;
          height: 25px;
          width: 25px;
          border-radius: 20px;
          border: none;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
          transition: all 0.3s;
          &:hover {
            //background-color: $newDominant1;
            background-color: $colors-V2-blue2;
            opacity: 1;
            //& img {
            //  filter: invert(100%);
            //}
          }
          &:disabled {
            opacity: 0.3;
            &:hover {
              background-color: white;
            }
            & img {
              opacity: 0.3;
            }
          }
          & img {
            width: 22px;
            height: 22px;
          }
        }
      }

      &__number {
        overflow: hidden;
        font-family: 'geomanistbold', 'Lato', sans-serif;
        font-size: 3.5rem;
        color: #eee;
      }

    }

  }

}

.kanban__candidacy--v2 {

  position: relative;

  margin: 4px 0;
  padding: 4px;
  width: 100%;
  height: 60px;
  background-color: #ddd;
  border: 4px solid #ddd;
  border-radius: 30px;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  animation: fade-in 0.3s;

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    overflow: hidden;
    background-color: #fcfcfc;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    font-size: 1.15rem;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-family: 'geomanistbold', 'Lato', sans-serif;
  }

  &__checkbox {
    width: 40px;
  }

  &__hollow {
    opacity: 0.3;
  }

  &--blocked {
    background-color: #dddddd;
    border: 4px solid #ddd;
  }
  &--imported {
    background-color: #03d1e5;
    border: 4px solid #03d1e5;
    & * {color: black;}
  }
  &--imported2 {
    background-color: #a15df1;
    border: 4px solid #a15df1;
    & * {color: black;}
  }
  &--color1 {
    //background-color: #f9c930;
    //border: 4px solid #f9c930;
    background-color: #37d23f;
    border: 4px solid #37d23f;
  }
  &--color2 {
    //background-color: #37d23f;
    //border: 4px solid #37d23f;
    background-color: #44c1fd;
    border: 4px solid #44c1fd;
  }
  &--color3 {
    //background-color: #44c1fd;
    //border: 4px solid #44c1fd;
    background-color: #f9c930;
    border: 4px solid #f9c930;
  }
  &--color4 {
    background-color: #e3540e;
    border: 4px solid #e3540e;
  }

  &--color5 {
    background-color: $redpink;
    border: 4px solid $redpink;
  }

  &--color6 {
    background-color: #0e76a8;
    border: 4px solid #0e76a8;
  }

  &__filters-active {
    border: 4px solid $colors-V2-dominant1;
  }

  &--white {

    background-color: white;
    border: 4px solid $grey;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    &__web-origin {
      position: absolute;
      top: 2px;
      left: 18px;
      font-weight: bolder;
      font-size: 0.8rem;
      color: $colors-V2-blue2;
    }

  }
}

.kanban__candidacy {
  margin: 3px 0;
  padding: 10px;
  //border-radius: 4px;

  cursor: pointer;

  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.28);

  transition: all 0.6s;

  &--cancelled {
    opacity: 0.3;
  }

  &__avatar {
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    transition: all 0.6s;

    &--big {
      width: 120px !important;
      height: 120px !important;
      font-size: 2.5rem !important;
    }

    &--replacement {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      transition: all 0.6s;
      background-color: $grey;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      font-weight: bolder;
      align-items: center;
      font-size: 1.4rem;
      letter-spacing: 1px;

      & .kanban__candidacy__avatar--big {
        width: 120px !important;
        height: 120px !important;
        font-size: 3rem;
      }

      &--cyan {
        background-color: lighten($dominant1, 40%);
      }
      &--yellow {
        background-color: lighten($yellow, 20%);
      }
      &--green {
        background-color: lighten($green, 20%);
      }
      &--pink {
        background-color: lighten($redpink, 40%);
      }
      &--violet {
        background-color: lighten($violet, 30%);
      }
    }
  }

  &--slim {
    padding: 10px;
    margin: 3px 0;
    & .kanban__candidacy__avatar {
      object-fit: cover;
      width: 40px;
      height: 40px;

      &--big {
        width: 120px !important;
        height: 120px !important;
      }
    }
    & .kanban__candidacy__avatar--replacement {
      width: 40px;
      height: 40px;
      font-size: 1.1rem;
    }
  }

  &--hollow {
    //background-color: white;
    //border: 1px dashed #ccc;

    opacity: 0.8;
    box-shadow: none;
  }
}

.kanban__candidacy {
  &--slim {
    & .kanban__candidacy__line1 {
      margin-bottom: 0;
    }
    & .kanban__candidacy__line2 {
      margin-bottom: 0;
    }
  }
  &__line1, &__line2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    transition: all 0.6s;
  }
  &__line3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    transition: all 0.6s;
  }
  &__line1 {
    //margin-bottom: 10px;
  }
  &__line2 {
    margin-bottom: 15px;
  }
}

.kanban__candidacy {
  &--blocked {
    border-left: 6px solid $blackish7 !important;
    border-right: 6px solid $blackish7 !important;
  }
  &--range1 {
    border-left: 6px solid desaturate(lighten($redpink, 10%), 5%)!important;
    border-right: 6px solid desaturate(lighten($redpink, 10%), 5%)!important;
  }
  &--range2 {
    border-left: 6px solid desaturate(lighten(#ff5f3b, 10%), 5%) !important;
    border-right: 6px solid desaturate(lighten(#ff5f3b, 10%), 5%) !important;
  }
  &--range3 {
    border-left: 6px solid desaturate(lighten($green, 10%), 5%) !important;
    border-right: 6px solid desaturate(lighten($green, 10%), 5%) !important;
  }
  &--range4 {
    border-left: 6px solid desaturate(lighten($yellow, 10%), 5%) !important;
    border-right: 6px solid desaturate(lighten($yellow, 10%), 5%) !important;
  }
}

.kanban__number {
  color: $grey;
  font-family: 'geomanistbold', 'Lato', sans-serif;
  font-size: 3rem;
}

.kanban__add {
  min-width: 250px !important;
}

.kanban__space {
  height: 6px;
}

.kanban__column__action {
  position: absolute;
  top: 18px;
  left: 15px;
  & img {
    width: 35px;
    height: 35px;
    opacity: 0.15;
  }
}

.kanban__unsorted__separator {
  margin: 50px 0 40px 0;
  border-bottom: 1px solid #eee;
}

.dark-orange{
  background-color: #f1b8b0;

}
.orange{
  background-color: #f3d4bb;
}

.yellow{
  background-color: #ead8b6;
}

.dark-yellow{
  background-color: #eaf1c2;
}

.green{
  background-color: #e4f8c6;
}