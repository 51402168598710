
.hr-soft {
  width: 85%;
  opacity: 0.8;
  margin: 20px auto;
}

.avatar-display {

  object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;

  &--middle {
    object-fit: cover;
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  &--big {
    object-fit: cover;
    width: 220px;
    height: 220px;
    border-radius: 50%;
  }
}

.full-width {
  box-sizing: border-box;
  width: 90%;
}

.hover-grey {
  padding: 5px 8px;
  &:hover {
    background-color: $grey;
  }
}

.hide-overflow {
  width: 100%;
  overflow: hidden !important;
}

.business-parameter-plan {
  background-color: lighten($colors-V2-blue2, 30%);
  width: 150px;
  height: 50px;
  border-radius: 25px;
  margin: 5px;
  border: 3px solid $colors-V2-blue2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1.12rem;
  color: $colors-V2-blue2 !important;
  &--disabled {
    opacity: 0.3;
  }
}

.business-parameter-plan--small {
  background-color: lighten($colors-V2-blue2, 30%);
  padding: 3px 10px;
  border-radius: 20px;
  margin: auto;
  border: 3px solid $colors-V2-blue2;
  color: $colors-V2-blue2 !important;
  font-size: 0.86rem !important;
  cursor: pointer;
}

.text--linkedin {
  margin: auto;
  padding: 20px 33px;
  background-color: $linkedin;
  color: white;
  text-align: center;
  border-radius: 12px;
}

.action-label {
  display: inline;
  padding: 5px 10px;
  margin: 0 8px;
  font-size: 0.72rem;
  background-color: $yellow;
  border-radius: 7px;
}

.comment__railenium {
  width: 60% !important;
  height: 290px;
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.39);
  text-align: left;
  padding: 20px 22px;
  font-size: 0.9rem;
}

.space-right--small {
  margin-right: 4px;
}

.div-center{
  display: flex;
  flex-flow: row;
  justify-content: center ;
  align-items: center;
}

.warning{
  font-size: 10px;
  color: red;
  margin-left: 10px;
}

.highlighted-text {
  background-color: yellow;
}