.business--blackbelt__manager{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #e3dddd;
  margin:10px;
  padding:10px;
  border-radius: 20px;

}

.business--blackbelt__manager-fullName{
  margin:3px 20px;
  width:150px;
}
.business--blackbelt__manager-email{
  margin:3px 20px;
  width:250px;
}
.business--blackbelt__add-manager{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.business--blackbelt__add-manager--title{
  text-align: center;
  //font-size: xx-large;
  //font-weight: bold;
}