.btn--share {

  margin: 5px 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: none;

  background-color: $yellow;

  &:hover {
    background-color: lighten($yellow, 15%);
  }

  &:active {
    background-color: darken($yellow, 5%);
  }

  & img {
    width: 25px;
    height: 25px;
  }

}

.icon-share {
  width: 25px;
  height: 25px;
  filter: invert(100%);
}