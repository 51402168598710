.missions-stats__metrics-div {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.missions-stats__metrics-item {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  border-radius: 20px;
  height: 130px;
  width: 170px;
  background-color: white;
  padding: 5px;

}

.missions-stats__metrics-item {

  &__title {
    font-size: 0.9rem;
    color: #aaa;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__number {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__date {
    font-family: 'geomanistblack', 'Lato', sans-serif;
    font-size: large;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.missions-stats__origin-div {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
}

.missions-stats__origin-item {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
  padding: 5px;
}

.missions-stats__origin-information {
  height: 100px;
}

.missions-stats__ambassador-information {
  height: 100px;
  overflow-y: scroll;
}

.flex-row {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.missions-stats__calendar-legend {
  margin: 5px;
  height: 135px;
}

.missions-stats__calendar-day {
  margin-top: 22px;
  margin-bottom: 22px;
  margin-right: 5px;
  font-size: x-small;
}

.missions-stats__calendar-months {
  margin-left: 60px;
}

.missions-stats__calendar-month {
  width: 80px;
  font-size: x-small;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.missions-stats__calendar {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;

  height: 135px;
  width: 950px;

}

.calendar-container {
  width: 100%;
  overflow: hidden;

  transform: Scale(0.95);

}

.missions-stats__calendar-item {
  border-radius: 3px;
  border: 1px solid #777;
  height: 10px;
  width: 10px;
  margin: 4px;

  &--invisible {
    opacity: 0;
  }
}

.missions-stats__calendar-item--color0 {
  background-color: white;
  opacity: 0.4;
}

.missions-stats__calendar-item--color1 {
  background-color: rgb(121, 199, 227);
}

.missions-stats__calendar-item--color2 {
  background-color: rgb(18, 147, 154);
}

.missions-stats__calendar-item--color3 {
  //background-color: rgb(4, 69, 72);
  background-color: rgb(55, 88, 195);
}

.missions-stats__calendar-item--color4 {
  background-color: rgb(26, 49, 119);
}

.missions-stats__legend-colors {
  margin-left: 700px;
}


