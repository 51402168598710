
.text--center {
  text-align: center;

  &--alert{
    color:red;
    font-size: 0.8rem;
  }

  &--forced {
    text-align: center !important;
  }

}

.text--i {
  font-style: italic;
}

.text--right {
  text-align: right;
}

.text--left {
  text-align: left;
}

.text--justify {
  text-align: justify;
}

.text--hollow {
  opacity: 0.35;
}

.text--hollow--hover {
  opacity: 0.35;
  transition: all 0.3s;
  &:hover {
    opacity: 0.85;
  }
}

.text--hide {
  opacity: 0;
}

.text--transition {
  transition: all 0.5s;
}

.text--grey {
  color: #4d4949;
}

.text--grey2 {
  color: #f1f1f1;
}

.text--dark-grey {
  color: #a0a0a0;
}

.text--white {
  color: #ffffff;
}

.text--black {
  color: $blackish8 !important;
}

.text--dominant1 {
  color: $dominant1;
}

.text--dominant2 {
  color: $dominant2;
}

.text--green {
  color: $green;
}

.text--cyan {
  color: $dominant1;
}

.text--red {
  color: $redpink;
}

.text--violet {
  color: $violet;
}

.text--bold {
  font-weight: bolder;
}

.text--magnified1x {
  font-size: 1.1rem;
}

.text--magnified11x {
  font-size: 1.16rem;
}

.text--magnified15x {
  font-size: 1.22rem;
}

.text--magnified2x {
  font-size: 1.3rem;
}

.text--magnified3x {
  font-size: 1.5rem;
}

.text--minified1x {
  font-size: 0.95rem;
}

.text--minified2x {
  font-size: 0.9rem;
}

.text--minified3x {
  font-size: 0.82rem;
}

.text--minified4x {
  font-size: 0.78rem;
}

.modern-link {
  text-decoration: underline;
  color: #188dff;
  transition: color 0.3s;
  &:hover {
    color: #188dff;
  }
}

.modern-link--grey {
  text-decoration: underline;
  color: #bbb;
  transition: color 0.2s;
  &:hover {
    color: #999;
  }
}

.modern-link--white {
  text-decoration: underline;
  color: white;
  transition: color 0.2s;
  &:hover {
    color: white;
  }
}

.modern-link--grey-dark {
  text-decoration: underline;
  color: #888;
  transition: color 0.2s;
  &:hover {
    color: #555;
  }
}
.img--very--small {
  height: 3px;
  width: 3px;
}

.img--small {
  height: 15px;
  width: 15px;
}

.img--btn {
  height: 22px;
  width: 22px;
}

.img--medium {
  display: inline !important;
  width: 28px !important;
  height: 28px !important;
}

.lato {
  //font-family: 'Lato', sans-serif;
}

.relative {
  position: relative;
}

.list-alternate-background {
  & li {
    background: white;
    padding: 8px;
  }
  & li:nth-child(odd) { background: $grey; }
}

.text--new-blue {
  color: $colors-V2-blue2;
}

.text--new-blue2 {
  color: $linkedin;
}
.text--new-blue3 {
  color: #063ef9;
}


.info-box {
  margin: auto;
  background-color: #ade3ff;
  padding: 40px 40px;
  font-size: 0.9rem;
  border-radius: 20px;
  width: 95%;
  max-width: 800px;
  & * {
    font-size: 0.9rem;
  }
}

.new-ul {

  display: list-item !important;
  list-style-type: circle !important;

  & li {
    display: list-item !important;
    list-style-type: circle !important;
    margin: 6px auto;
  }
}

.btn--transparent{
  border: none;
  background-color: transparent;
}



.margin3{
  margin-right: 3px;
}