
.section-apps__container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.section-apps {

  padding: 10px;
  margin: 15px auto;

  width: auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  @include sm-only {
    flex-flow: column nowrap;
    justify-content: center;
  }

  @include md-only {
    justify-content: center;
  }

  &__app {

    position: relative;
    width: 160px;
    height: 200px;
    background-color: white;
    border: black 1px solid;
    padding: 5px 10px;
    margin: 15px 38px;
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    justify-items: center;
    align-items: center;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    .credit {
      @include sm-only {
        font-size: 1rem;
      }
      position: absolute;
      margin-top: 165px;
      font-size: 0.7rem;
      color: black;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;

    }

    @include sm-only {
      width: 95%;
      margin: 10px auto;
    }

    &--unavailable {
      opacity: 0.3;

      &:hover {
        cursor: not-allowed;
      }

    }

    & img {
      @include sm-only {
        width: 60px;
      }
      height: auto;
      width: 50px;
      padding-top: 5px;
    }

    & h1 {
      @include sm-only {
        font-size: 1rem;
      }
      font-family: 'Lato', sans-serif;
      font-size: 0.9rem;
      font-weight: bold;
      text-align: center;
      color: black;
    }

    & desc {
      @include sm-only {
        font-size: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      color: dimgrey;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 0.7rem;

    }
  }

}

.section-apps__app__slider--top-left {
  position: absolute;
  top: 5px;
  left: 5px;
}

.section-apps__app__slider {

  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  justify-items: flex-start;

  margin: 5px;

  .switch {
    @include sm-only {
      width: 55px;
      height: 26px;
    }
    position: relative;
    display: inline-block;
    width: 30px;
    height: 14px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    //cursor: pointer;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
  }

  .slider:before {
    @include sm-only {
      height: 22px;
      width: 22px;
      bottom: 2px;
    }
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 1px;
    background-color: white;
  }

  input:checked + .slider {
    background-color: limegreen;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px limegreen;
  }

  input:checked + .slider:before {
    @include sm-only {
      -webkit-transform: translateX(24px);
      -ms-transform: translateX(24px);
      transform: translateX(24px);
    }
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }

  /* Rounded sliders */
  .slider.round {
    @include sm-only {
      border-radius: 26px;
    }
    border-radius: 14px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.section-apps__title {
  //font-family: 'geomanistblack', 'Lato', sans-serif;
  font-weight: bolder;
  font-size: 1.2rem;
  margin-top: 80px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}