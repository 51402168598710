
.arrow-btn {

  position: relative;
  height: 20px !important;

  transform: rotateZ(0deg);
  transition: all 0.3s;

  margin: 0 12px 0 10px;

  &--activated {
    transform: rotateZ(90deg);
  }

}