
.btn-picto {

  width: 35px;
  height: 35px;

  background-color: $blackish4;
  background-image: linear-gradient(to bottom, lighten($blackish4, 20%), $blackish4) ;

  border: none;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  margin: 5px;

  border-radius: 5px;

  box-shadow: none;

  transform: scale(1);

  transition: all 0.2s;

  &:hover {
    //transform: rotate3d(0,1,0,45deg);
    transition: all 0.2s;

    //transform: scale(1.07);
    box-shadow: 0px 2px 6px 0px rgba(black,0.3);
  }

  &:disabled {
    opacity: 0.7;
    &:hover {
      transform: scale(1);
      box-shadow: none;
    }
  }

  & img {
    width: 30px;
    height: 30px;
    filter: invert(100%);
  }

  @include sm-only {
    width: 40px;
    height: 40px;
    margin: 5px;
    & img {
      width: 36px;
      height: 36px;
      filter: invert(100%);
    }
  }

}


.btn-picto {

  &--blue {
    background-color: $linkedin;
    background-image: linear-gradient(to top, $linkedin, lighten($linkedin, 30%));
    color: white;
    &:hover {
      background-image: linear-gradient(to top, lighten($linkedin, 10%), lighten($linkedin, 40%));
      box-shadow: 0px 2px 6px 0px rgba($linkedin,0.3);
    }
    &:disabled {
      background-color: #bbb;
      background-image: linear-gradient(to top, #bbb, lighten(#bbb, 20%));
      color: white;
    }
  }

  &--in {
    position: relative;
    width: 35px;
    height: 35px;
    background: transparent;
    border: none;
    border-radius: 5px;
    box-shadow: none;
    margin: 5px;
    overflow: hidden;
    //background-color: $linkedin;
    //background-image: linear-gradient(to top, $linkedin, lighten($linkedin, 30%));
    //color: white;
    & img {
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
    }
    &:hover {
      //background-image: linear-gradient(to top, lighten($linkedin, 10%), lighten($linkedin, 40%));
      box-shadow: 0px 2px 6px 0px rgba($linkedin,0.3);
    }
    &:disabled {
      //background-color: #bbb;
      //background-image: linear-gradient(to top, #bbb, lighten(#bbb, 20%));
      //color: white;
    }
  }

  &--green {
    background-color: hsl(155, 100%, 35%);
    background-image: linear-gradient(to top, hsl(155, 100%, 35%), lighten(hsl(155, 100%, 35%), 20%));
    color: white;
    &:hover {
      background-image: linear-gradient(to top, lighten(hsl(155, 100%, 35%), 7%), lighten(hsl(155, 100%, 35%), 37%));
      box-shadow: 0px 2px 6px 0px rgba(hsl(155, 100%, 35%),0.3);
    }
    &:disabled {
      background-color: #bbb;
      background-image: linear-gradient(to top, #bbb, lighten(#bbb, 20%));
      color: white;
    }
  }

  &--cv {
    background-color: $cyan;
    background-image: linear-gradient(to top, $cyan, lighten($cyan, 20%));
    color: white;
    &:hover {
      background-image: linear-gradient(to top, lighten($cyan, 7%), lighten($cyan, 37%));
      box-shadow: 0px 2px 6px 0px rgba($cyan,0.3);
    }
    &:disabled {
      background-color: #bbb;
      background-image: linear-gradient(to top, #bbb, lighten(#bbb, 20%));
      color: white;
    }
  }

  &--dominant {
    background-color: $dominant1;
    background-image: linear-gradient(to top, $dominant1, lighten($dominant1, 20%));
    color: white;
    &:hover {
      background-image: linear-gradient(to top, lighten($dominant1, 7%), lighten($dominant1, 37%));
      box-shadow: 0px 2px 6px 0px rgba($dominant1,0.3);
    }
    &:disabled {
      background-color: #bbb;
      background-image: linear-gradient(to top, #bbb, lighten(#bbb, 20%));
      color: white;
    }
  }

  &--violet {
    background-color: $violet;
    background-image: linear-gradient(to top, $violet, lighten($violet, 20%));
    color: white;
    &:hover {
      background-image: linear-gradient(to top, lighten($violet, 7%), lighten($violet, 37%));
      box-shadow: 0px 2px 6px 0px rgba($violet,0.3);
    }
    &:disabled {
      background-color: #bbb;
      background-image: linear-gradient(to top, #bbb, lighten(#bbb, 20%));
      color: white;
    }
  }

  &--white {
    background-color: #bbb;
    background-image: linear-gradient(to top, #bbb, lighten(#bbb, 20%));
    color: white;
    //&:hover {
    //  background-image: linear-gradient(to top, lighten(#bbb, 10%), lighten(#bbb, 28%));
    //  box-shadow: 0px 2px 6px 0px rgba(hsl(155, 100%, 95%),0.3);
    //}
  }
}

.btn-picto--text {
  font-family: 'geomanistblack', 'Lato', sans-serif;
  font-size: 0.95rem;
  color: #555 !important;
  &:hover {
    color: #444 !important;
  }
}

.btn-pin {
  width: 40px;
  height: 40px;

  border: none;
  color: #ddd;

  border-radius: 6px;

  background-color: white;

  & img {
    opacity: 0.3;
  }

  &--active {
    & img {
      opacity: 1 !important;

    }
    //background-color: $grey;
  }
}

.btn-picto--minimalist {
  width: 30px;
  height: 30px;
  border: none;
  color: #ddd;
  border-radius: 6px;
  background-color: white;
  margin: 0px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  opacity: 0.4;

  & img {
    //opacity: 0.3;
    width: 30px;
    height: 30px;
  }
  &:hover {
    opacity: 0.8 !important;
    & img {
      //opacity: 0.8 !important;
    }
  }
  &:disabled {
    opacity: 0.1 !important;
    & img {
      //opacity: 0.1 !important;
    }
  }
  &--active {
    opacity: 0.9 !important;
    & img {
      //opacity: 0.8 !important;
    }
  }
  &--small {
    //width: 22px;
    //height: 22px;
    & img {
      width: 19px;
      height: 19px;
    }
  }
}

.btn-picto--minimalist2 {
  width: 30px;
  height: 30px;
  border: none;
  color: #ddd;
  border-radius: 6px;
  background-color: white;
  margin: 0px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  & img {
    opacity: 0.3;
    width: 30px;
    height: 30px;
  }
  &:hover {
    & img {
      opacity: 0.8 !important;
    }
  }
  &:disabled {
    & img {
      opacity: 0.1 !important;
    }
  }
  &--active {
    & img {
      opacity: 0.8 !important;
    }
  }
  &--small {
    //width: 22px;
    //height: 22px;
    & img {
      width: 19px;
      height: 19px;
    }
  }
}

.btn-picto--minimalist2_without-background {
  width: 30px;
  height: 30px;
  border: none;
  color: #ddd;
  background-color: #f6f2ed;
  border-radius: 6px;
  margin: 0px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  & img {
    opacity: 0.3;
    width: 30px;
    height: 30px;
  }
  &:hover {
    & img {
      opacity: 0.8 !important;
    }
  }
  &:disabled {
    & img {
      opacity: 0.1 !important;
    }
  }
  &--active {
    & img {
      opacity: 0.8 !important;
    }
  }
  &--small {
    //width: 22px;
    //height: 22px;
    & img {
      width: 19px;
      height: 19px;
    }
  }
}