.first-login-assistant-btn{
  background-color: white;
  border: 2px solid #ffc72e;
  color:#ffc72e;
  padding: 11px 19px;
  margin: 7px;
  border-radius: 6px;
}

.first-login-assistant-btn--selected{
  background-color:#ffc72e;
  color:white;
  border: 2px solid white;
}

.first-login-assistant-btn-back{
  width:40px;
  height: 40px;
  border:none;
  background-color: transparent;
}

.first-login-assistant-rotateZ{
  transform: rotateZ(180deg);
}

.first-login-assistant-btn-back-container{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.first-login-assistant-select{
  width:250px!important;
}

.first-login-assistant-phone{
  width:170px !important;
}