
/*
Template should be used for communities
 */

.pool-template {

  display: grid;

  grid-template-columns: 320px auto;
  grid-template-rows: auto;
  grid-template-areas: "nav body";

  @include md-only {
    grid-template-columns: 260px auto;
  }

  @include sm-only {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "nav" "body";
  }

  &__body {

    grid-area: body;

    background-color: $colors-V2-layout-grey;

  }

  &__nav {
    grid-area: nav;

    background-color: #dfdfdf;

    &__content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: stretch;
      padding: 30px 20px 30px 18px;
      @include sm-to-md {
        padding: 10px;
      }
      //position: sticky;
      //top:60px;
    }
  }

  &__research {
    width: 100%;
    background-color: #c4d2d2;
    padding: 80px 40px 40px 40px;
  }

}

/////////////////////////////////////////////////////:

.pool-template__candidates {

  padding: 40px 40px 40px 60px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;

}

.pool-template__candidate {

  position: relative;

  background-color: cadetblue;
  border: 4px solid cadetblue;

  margin: 5px;

  padding: 4px;

  height: 60px;

  width: 290px;
  max-width: 270px;
  flex-grow: 1;

  border-radius: 30px;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  animation: fade-in 0.3s;

  &__filters-active {
    border: 4px solid darken($colors-V2-blue2, 20%) !important;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    overflow: hidden;
    background-color: #fcfcfc;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    font-size: 1.15rem;

    &__available {
      border: 8px solid $violet;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-family: 'geomanistbold', 'Lato', sans-serif;
  }

  &__checkbox {
    width: 40px;
  }

  &--refused-gdpr {
    background-color: transparent;
    border: 6px solid $redpink !important;
  }
  &--old5 {
    background-color: #ddd;
    border: 4px solid #ddd;
  }
  &--old4 {
    background-color: #e3540e;
    border: 4px solid #e3540e;
  }
  &--old3 {
    background-color: #f9c930;
    border: 4px solid #f9c930;
  }
  &--old2 {
    background-color: #44c1fd;
    border: 4px solid #44c1fd;
  }
  &--old1 {
    background-color: #37d23f;
    border: 4px solid #37d23f;
  }

}

.pool-template__numbers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.pool-template__numbers-title {
  font-family: 'geomanistbold', 'Lato', sans-serif;
  font-size: 0.9rem;
}
.pool-template__numbers-circle {
  background-color: white;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.available-ban {
  font-family: 'geomanistblack', 'Lato', sans-serif;
  font-size: 0.6rem;
  box-shadow: 0px 2px 6px 0px rgba(black, 0.6);
  position: absolute;
  top: -12px;
  left: 45px;
  font-weight: bolder;
  background-color: $yellow;
  //border: 3px solid black;
  padding: 2px;
  border-radius: 15px;

}

.pool-template__new{
  position: relative;
  top: -29px;
  left: 97px;
  margin: 2px;
  padding: 2px;
  width: 55px;
  font-size: 0.6rem;
  background-color: #ef1a1a;
  border-radius: 20px;
  box-shadow: 0px 2px 6px 0px rgba(black, 0.6);
  cursor: pointer;
  animation: fade-in 0.3s;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-family: 'geomanistbold', 'Lato', sans-serif;
}