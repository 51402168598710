
.landing-page--v2__splash {

  //background-color: $colors-V2-blue2;

  padding: 160px 0 60px;

  &__side-by-side {
    width: 95%;
    //max-width: 1440px;
    margin: auto;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    @include sm-to-md {
      flex-flow: column nowrap;
    }

    &__box {
      @include sm-to-md {
        margin-top: 25px;
      }
    }

    &__box1 {
      width: 564px;
      padding: 20px;
      @include sm-to-md {
        width: 95%;
      }
    }

    &__box2 {
      //width: 640px;
      //height: 480px;

      margin-left: 30px;

      @include sm-to-md {
        width: 95%;
        margin-top: 60px;
      }
    }
  }

}

.landing-page--v2__video-container {
  width: 600px;
  height: 400px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 2px 18px 0 rgba(#fff, 0.4);
  &--grey {
    border: 1px solid #bbb;
    //border-left: 15px solid $blackish6 !important;
    @include sm-to-md {
      border: none;
    }
  }

  @include sm-to-md {
    width: 95%;
    height: 300px;
  }
}

.landing-page--v2__video-container__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing-page--v2__splash__title {
  font-size: 2.3rem;
  color: white;
  font-family: 'geomanistbold', 'Lato', sans-serif;
  line-height: 1.2;
  margin-bottom: 0;
  @include sm-to-md {
    font-size: 1.8rem;
    text-align: center;
  }
}

.landing-page--v2__splash__subtitle {
  margin-top: 25px;
  font-size: 1.15rem;
  color: black;
  line-height: 1.73;
  @include sm-to-md {
    font-size: 1rem;
    text-align: center;
  }
}

.landing-page--v2__splash__button-space {
  margin-top: 25px;
  @include sm-to-md {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}

.landing-page--v2__splash__button {
  background-color: white;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  border: none;

  transition: all 0.2s;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 2px 18px 0 rgba(#fff, 0.4);
    background-color: #fffd;
  }

  & img {
    width: 7px;
    margin: 0 0 0 10px;
  }
}

.landing-page--v2__title {
  text-align: left;
  font-family: 'geomanistbold', 'Lato', sans-serif;
  color: $blackish6;
  font-size: 1.8rem;
  @include sm-to-md {
    font-size: 1.6rem;
    text-align: center;
  }
}

.landing-page--v2__title2 {
  text-align: center;
  font-size: 1.8rem;
  @include sm-to-md {
    font-size: 1.4rem;
  }
}

.landing-page--v2__text {
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.8;
  @include sm-to-md {
    text-align: center;
  }
}

.container-lp {
  width: 95%;
  max-width: 1440px;
  margin: 80px auto 80px;
  @include sm-to-md {
    margin: 40px auto 0;
  }
}

.landing-page--v2__adopters {
  width: 95%;
  max-width: 1000px;

  margin: auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  &__adopter {
    width: 120px;
    overflow: hidden;
    margin: 30px 50px;

    @include sm-to-md {
      margin: 30px 20px;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}


.landing-page--v2__side-by-side {
  width: 95%;
  margin: auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;

  @include sm-to-md {
    flex-flow: column nowrap;
    align-items: center;
  }

  &__box {
    width: 95%;
    max-width: 624px;
    //margin: auto;
    @include sm-to-md {
      //margin: 35px auto 0;
      width: 95%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }
  }

  &__box1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  &__box2 {
    padding: 40px;
  }
}

.lpfull {
  width: 100%;
}


.landing-page--v2-bg {

  &--shape1 {
    background-image: url("https://static.myshortlist.co/shape1.svg");
    background-repeat: no-repeat;
    background-size: contain;
    @include sm-to-md {
      background-size: cover;
    }
  }

  &--shape2 {
    background-image: url("https://static.myshortlist.co/shape2.svg");
    background-repeat: no-repeat;
    background-position: top -130px left;
    background-size: cover;
  }

  &--shape3 {
    background-image: url("https://static.myshortlist.co/shape3.svg");
    background-repeat: no-repeat;
    background-position: top -130px left;
    background-size: cover;
  }

  &--shape4 {
    background-image: url("https://static.myshortlist.co/shape4.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
  }
}

.landing-page--v2__navbar {

  position: absolute;

  top: 0;
  left: 0;

  background-color: transparent;

  padding: 25px 80px;

  @include sm-to-md {
    padding: 20px 13px;
  }

  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &__logo {

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    &__square {

      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 8px;
      background-color: black;

      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      transform: rotateZ(17deg);

      @include sm-only {
        width: 38px;
        height: 38px;
      }

      &__text {
        transform: rotateZ(-17deg);
        position: relative;
        top: -5px;
        color: white;
        font-size: 1.4rem;
        font-family: 'Pacifico', cursive;
        @include sm-only {
          font-size: 1.2rem;
        }
      }
    }
    &__text {
      margin-left: 15px;
      font-size: 2.3rem;
      color: white;
      font-family: 'Pacifico', cursive;
      @include sm-only {
        font-size: 1.5rem;
      }
    }
  }

  &__buttons {

  }

  &__button {
    background-color: white;
    width: 120px;
    height: 40px;
    border-radius: 20px;
    border: none;
    font-size: 0.95rem;

    transition: all 0.2s;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &:hover {
      box-shadow: 0 2px 18px 0 rgba(#fff, 0.4);
      background-color: #fffd;
    }

    @include sm-only {
      width: 100px;
      height: 34px;
      border-radius: 17px;
    }
  }
}