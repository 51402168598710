.btn-main {

  position: relative;

  width: auto;

  padding: 11px 19px;
  margin: 7px;

  font-size: 1rem;

  overflow: hidden;

  transition: all 0.2s;

  //background-color: $dominant1;
  //background-image: linear-gradient(to top, $dominant1, lighten($dominant1, 16%));

  background-color: $yellow;
  background-image: linear-gradient(to top, $yellow, lighten($yellow, 16%));

  border: none;
  color: black;

  border-radius: 6px;

  &:hover {
    background-color: darken($yellow, 8%);
    background-image: linear-gradient(to top, lighten($yellow, 10%), lighten($yellow, 25%));
    box-shadow: 0 2px 5px 0 rgba(lighten($yellow, 10%),0.7);
  }

  &:disabled {
    width: auto;
    opacity: 0.5;
    //background-color: lighten($yellow, 25%);
    //background-image: linear-gradient(to top, lighten($yellow, 20%), lighten($yellow, 35%));
    //color: #999;
    &:hover {
      box-shadow: none;
    }
  }

  &--small {
    padding: 9px 15px;
  }

  @include sm-only {
    padding: 9px 15px;
    font-size: 0.95rem;
  }

  &--micro {
    padding: 7px 12px;
    font-size: 0.9rem;
    border-radius: 6px;
  }

  &--nano {
    padding: 4px 8px;
    font-size: 0.9rem;
    border-radius: 6px;
  }

  &--bigger {
    padding: 15px 25px;
  }

  &--full {
    width: 100%;
  }

  &--empty {
    background-color: white;
    color: $dominant1;
    &:hover {
      background-color: $dominant1;
      color: $dominant1text;
    }
    &:disabled {

    }
  }

  &--animated-click {
    &:active {
      top: 2px;
      box-shadow: 0px 0px 9px 0px rgba($dominant1,0.5);
    }
  }

}

.btn-main--white {

  background-color: white;
  border-color: white;

}

.btn-main--transparent {

  opacity: 0.4;


}


.btn-main--v2 {
  background-color: $newDominant1;
  background-image: linear-gradient(to top, $newDominant1, lighten($newDominant1, 16%));
  &:hover {
    background-color: darken($newDominant1, 8%);
    background-image: linear-gradient(to top, lighten($newDominant1, 10%), lighten($newDominant1, 25%));
    box-shadow: 0 2px 5px 0 rgba(lighten($newDominant1, 10%),0.7);
  }

  &:disabled {
    width: auto;
    background-color: lighten($newDominant1, 40%);
    background-image: linear-gradient(to top, lighten($newDominant1, 30%), lighten($newDominant1, 45%));
    color: #999;
    &:hover {
      box-shadow: none;
    }
  }
}

.btn-main--linkedin {
  background-color: $linkedin;
  background-image: linear-gradient(to top, $linkedin, lighten($linkedin, 16%));
  color: white;
  font-size: 0.98rem;
  &:hover {
    background-color: darken($linkedin, 8%);
    background-image: linear-gradient(to top, lighten($linkedin, 10%), lighten($linkedin, 25%));
    box-shadow: 0 2px 5px 0 rgba(lighten($linkedin, 10%),0.7);
  }

  &:disabled {
    width: auto;
    background-color: lighten($linkedin, 40%);
    background-image: linear-gradient(to top, lighten($linkedin, 30%), lighten($linkedin, 45%));
    color: white;
    &:hover {
      box-shadow: none;
    }
  }
}