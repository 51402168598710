

$header-height: 495px;

$header-content-width: 1300px;

$grid-width: 1300px;

$el-single-width: 280px;
$el-single-height: 185px;
$el-margin: 7px;

$el-double-width: 2 * $el-single-width + 2 * $el-margin;
$el-double-height: 2 * $el-single-height + 2 * $el-margin;





