
.sources {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  background-color: gray;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;

  & > div {
    background-color: white;
    padding: 20px 10px;
    border-radius: 15px;
    flex: 1;
    margin: 0 10px;
    height: 100%;
  }
}

.link {
  color: #0077ff;
}