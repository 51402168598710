
/**
* White canvas for animation
*/
.loading-animation--fullpage {

  width: 100%;
  height: 100vh;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

}

/**
* Loading 2
*/

$animationLength: 1.5s;
$animationRadius: 18px;
$dotSize: 12px;

.loader {
  position: relative;
  width: $animationRadius * 3 + $dotSize;
  height: $dotSize;
  margin: $animationRadius auto;
  z-index: 100;
}

.dot {
  display: inline-block;
  width: $dotSize;
  height: $dotSize;
  border-radius: $dotSize * 0.5;
  background: #e4e4e4;
  position: absolute;
}

.dot_1 {
  animation: animateDot1 $animationLength linear infinite;
  left: $animationRadius;
  background: lighten(#ff0000, 20%);
}

.dot_2 {
  animation: animateDot2 $animationLength linear infinite;
  animation-delay: $animationLength % 3;
  left: $animationRadius * 2;
}

.dot_3 {
  animation: animateDot3 $animationLength linear infinite;
  left: $animationRadius;
  //background: lighten(#0000ff, 20%);
}

.dot_4 {
  animation: animateDot4 $animationLength linear infinite;
  animation-delay: $animationLength % 3;
  left: $animationRadius * 2;
}

@keyframes animateDot1 {
  0%{ transform: rotate(0deg) translateX(-$animationRadius); }
  25% { transform: rotate(180deg) translateX(-$animationRadius); }
  75% { transform: rotate(180deg) translateX(-$animationRadius); }
  100% { transform: rotate(360deg) translateX(-$animationRadius); }
}

@keyframes animateDot2 {
  0%{ transform: rotate(-0deg) translateX(-$animationRadius); }
  25% { transform: rotate(-180deg) translateX(-$animationRadius); }
  75% { transform: rotate(-180deg) translateX(-$animationRadius); }
  100% { transform: rotate(-360deg) translateX(-$animationRadius); }
}

@keyframes animateDot3 {
  0%{ transform: rotate(0deg) translateX($animationRadius); }
  25% { transform: rotate(180deg) translateX($animationRadius); }
  75% { transform: rotate(180deg) translateX($animationRadius); }
  100% { transform: rotate(360deg) translateX($animationRadius); }
}

@keyframes animateDot4 {
  0%{ transform: rotate(-0deg) translateX($animationRadius); }
  25% { transform: rotate(-180deg) translateX($animationRadius); }
  75% { transform: rotate(-180deg) translateX($animationRadius); }
  100% { transform: rotate(-360deg) translateX($animationRadius); }
}