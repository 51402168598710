@mixin flex-line-center--column-on-smartphones {

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  @include sm-only {
    flex-flow: column nowrap;
  }
}