
.advanced-research-result {
  width: 95%;
  max-width: 900px;
  margin: 10px auto;


  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  //border-radius: 8px;

  padding: 20px 20px;

  //background-color: $grey;
  border-bottom: 1px solid #444;

  &__header {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    &__name {
      font-size: 1.05rem;
      color: #063ef9;
      font-weight: bolder;
    }
  }

  &__section {

    margin: 8px 0;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    &__left {
      width: 120px;
      text-align: left;
      font-size: 0.9rem;
      font-weight: bolder;
      color: black;
      overflow: hidden;
      flex-shrink: 0;
    }
  }
}

.advanced-research-result__list {
  border-left: 1px solid black;
  padding-left: 15px;
}

.advanced-research__operator {
  font-size: 0.7rem !important;
  padding: 0 0 0 10px !important;
  background: transparent;
  border: none !important;
  color:#063ef9;
}