.subscribe-title {
  font-family: "geomanistblack", "Lato", sans-serif;
  font-size: 2rem;
}

.subscribe-section {
  width: 95%;
  max-width: 1000px;
  margin: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}