.candidate-profile__avatar{
  width:90px;
  height:90px;
  border-radius: 50%;
}

.candidate-profile__name{
  font-weight: bold;
}

.candidate-profile__btn{
  color: rgb(12, 167, 137);
  background-color: white;
  border: 2px solid rgb(12, 167, 137);
  border-radius: 10px;
  margin:5px;
}