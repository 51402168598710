.black-belt-window__container{
  margin: 20px;
}
.black-belt-window__input{
  box-sizing: content-box;
  padding: 0 10px !important;
  height: 30px !important;
  border-radius: 15px !important;
  border: 1px solid #bbb !important;
  margin:10px;
}

.black-belt-window__textarea{
  box-sizing: content-box;
  padding: 0 10px !important;
  height: 120px !important;
  border-radius: 15px !important;
  border: 1px solid #bbb !important;
  margin:10px;
}

.black-belt-window__title {
  color: $blackish8;
  font-size: 1.1rem;
  font-weight: bold;
}

.black-belt-window__btn{
  border-radius: 15px;
  border: none;
  background: $colors-V2-blue2;
  font-weight: bolder;
  color: white;
  transition: all 0.2s;
  margin: 10px;
  padding: 5px;

  &:hover {
    background: lighten($colors-V2-blue2, 15%);
  }

  &:disabled {
    opacity: 0.4;
  }
}

.black-belt-window__tchat-container{
  width: 400px;
  height:260px;
  border:none;
  background-color: #C4C4C4;
  border-radius: 10px;
  margin:2px;
  overflow-y: scroll;
}

.black-belt-window__tchat-input{
  width: 300px !important;
  height:40px !important;
  border:none !important;
  background-color: #C4C4C4 !important;
  border-radius: 10px !important;
  margin:2px!important;
}



.black-belt-window__tchat-btn{
  width: 100px ;
  height:40px ;
  border:none ;
  background-color: #C4C4C4 ;
  border-radius: 10px ;
  margin:2px;
  color:white;
  font-weight: bold;
}

.black-belt-window__tchat-title{
  font-weight: bold;
}

.black-belt-window__tchat-item{
  margin:5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.black-belt-window__tchat-avatar{
  width:40px;
  height: 40px;
  border-radius: 20px;
  font-size: small;
  font-weight: bold;
  border: 2px solid black;
  margin:10px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.black-belt-window__container--mini {
  margin: 10px;
  padding: 20px;
  background-color: #eee;
}

.black-belt-window__keep{
  background-color: #37d23f;
  margin: 10px;
  padding: 10px;
  border-radius: 20px;

}
.black-belt-window__not-keep{
  background-color: #e3540e;
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
}


table, th, td {
  border: 1px solid black;
  padding:15px;
}

.black-belt-window__row{
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.window__block {
  margin: 20px auto;
  & h2 {
    font-size: 1.2rem;
    font-weight: bolder;
  }
  background-color: $colors-V2-layout-grey;
  border-radius: 15px;
  padding: 10px 30px;
}

.btn--green {
  background-color: green !important;
}

.btn--red {
  background-color: red !important;
}

.btn--orange {
  background-color: darkorange !important;
}

.paw2 {
  margin-right: 15px;
}