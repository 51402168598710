
.container {
  box-sizing: border-box;
  max-width: 1100px;
  //min-width: 950px;
  margin: auto;
  overflow: hidden;

  @include sm-to-md {

    max-width: 100%;
    width: 100%;
    padding: 0px 12px;
    //min-width: 0px;
  }

}


.container--small {
  box-sizing: border-box;
  max-width: 720px;
  margin: auto;

  @include sm-only {

    max-width: 100%;
    width: 100%;
    //padding: 0px 12px;
  }

}



.container--smaller {
  box-sizing: border-box;
  display: inline-block;
  max-width: 470px;
  margin: auto;

  @include sm-only {

    max-width: 100%;
    width: 100%;
    //padding: 0px 12px;
  }

}