.advanced-search {

  display: grid;
  grid-template-columns: 320px auto 130px;

  &__navbar {
    background-color: #f2f5ff;
  }

  &__results {
    &__header {
      text-align: left;
      padding-left: 280px;
      &__title {
        font-family: 'geomanistblack', 'Lato', sans-serif;
        color: $blackish6;
        font-size: 3.6rem;
        letter-spacing: 1px;
        line-height: 1;
      }
    }
  }

  &__actions {
    background-color: #f2f5ff;

    &__sticky {
      text-align: center;
      position: sticky;
      top: 120px;
      color:#063ef9;
    }
  }

  &__offset {
    position: relative;
    right: 20px;
  }
}

.advanced-research-filters {
  background-color: $grey;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 320px;
  height: 100vh;
  overflow-y: auto;
  z-index: 1000000000;
}

.advanced-research-filter {
  margin: 10px;
}

.advanced-research-title {
  font-weight: bold;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 22px;
  font-size: 0.9rem;
  color:#063ef9
}

.advanced-research-filters__input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  width: 190px;
  position: relative;
  //top: -10px;
  &--selected {
    border-bottom: 2px solid $linkedin;
  }
}

.advanced-research-filters__items {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.advanced-research-filters__item {
  border-radius: 20px;
  background-color: $linkedin;
  color: white;
  position: relative;
  padding: 5px 10px;
  margin: 2px;
  &--red {
    background-color: $red;
  }
}

.advanced-research__button-plus {
  background-color: transparent;
  border: none;
  position: relative;
  //top: -10px;
  font-size: 1.2rem;
  font-weight: bolder;
  &:disabled {
    opacity: 0.3;
  }
}

.advanced-research__button {
  background-color: transparent;
  border: none;
}

.advanced-research__button-search {
  background-color: #063ef9;
  color: white;
  border: none;
  border-radius: 30px;
  margin-bottom: 30px;
  padding: 15px 20px;
  text-align: center;
  margin-left: 9px;
  cursor: pointer;
  font-weight:bold;

  &:disabled {
    opacity: 0.3;
  }

}

.filters-input {

  &--selected {
    border: 2px solid $linkedin !important;
  }
}

.search-specifier {
  color: #063ef9;
  font-size: 0.9rem;
  background-color: #f2f5ff;
  border: 2px solid #063ef9;
  padding: 8px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 3px;
  & label {
    font-size: 0.8rem;

  }
}