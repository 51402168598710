.display-feedback-on-platform{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.display-feedback-on-platform__item{
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height:200px;
  width:300px;
  border-radius: 20px;
  margin:5px;
  padding: 15px;
  background-color: white;
  box-shadow: 0 6px 10px 0 rgba(26, 25, 25, 0.18);
}

.display-rating-component {

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &--white {
    transition: all 0.3s;
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    margin: 10px 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    @include sm-only {
      margin: 8px 8px;
    }

    & img {
      opacity: 0.3;
    }

  }


}

.display-rating-component--selected {
  & img {
    opacity: 1;
  }

}

.display-rating--component--btn--img {

  width: 20px;
  height: 20px;
}



