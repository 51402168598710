
.ambassadors--v2 {

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  &__ambassador {

    margin: 6px auto;
    width: 95%;
    max-width: 900px;

    height: 50px;
    border-radius: 20px;
    padding: 5px;

    background-color: white;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @include sm-only {
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 5px;
    }

    animation: fade-in 0.3s;

    &__left {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      @include sm-only {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      }
    }

    &__right {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      @include sm-only {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      }
    }

    &__element {
      margin: 10px;
      @include sm-only {
        margin: 10px;
      }
    }


    &__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $colors-V2-blue2;
      overflow: hidden;
      color: white;
      font-size: 1.8rem;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      & img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }


    &__button {
      margin: 5px;
      font-family: 'geomanistblack', 'Lato', sans-serif;
      font-size: 1.1rem;
      color: white;
      background-color: #bbb;
      height: 40px;
      padding: 0 15px;
      border-radius: 20px;
      border: none;
      transition: all 0.2s;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: $yellow;
      }
    }

    &--grey {
      background-color: $grey;
    }

  }
}

.ambassador-form__line {
  @include flex-line-center--column-on-smartphones;
}

.ambassador-form__column{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.ambassador__div{
  width:100px;
}

.ambassador__div-tag{
  width:100px;
  display:flex;
  flex-flow: column;
  justify-content:flex-start;
  align-item:flex-start;
}

.ambassador__tel-value{
  width: 175px !important;
}
.ambassador__tag{
  width: 210px !important;
}

.ambassador__tag-btn{
  width: 40px;
  height: 40px;
  border:none;
  background-color: transparent;
}
.ambassador__input{
  border-radius: 20px!important;
  &-red{
    background-color: #cb5151 !important;
  }
  &-green{
    background-color: #64ba64 !important;
  }
}

.ambassador__business-avatar{
  width:170px;
  height:170px;
  border-radius: 50%;
  object-fit: cover;
}

.ambassador-diffusion__header{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center ;
}

.ambassador-diffusion__input{
  border-radius: 20px!important;
  border:1px solid black ;
}

.ambassador-form__tags {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  margin: auto;

  &__tag {
    margin: 5px;
    padding: 5px 10px;
    background-color: #bbb;
    border-radius: 5px;
  }
}
.ambassador__slider{
  margin: 10px !important;
}

.ambassador-container{
  margin: auto;
  max-width: 800px;
  max-height: 300px;
  overflow-y: scroll;
}

.ambassador--transparency{
  & * {
    opacity: 0.6;
  }
}
.ambassador-subscription-from-mission{

  display:flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  padding: 5px 10px;

  border-radius: 6px;

  &--green {
    background-color: #99deda;
  }

  @include sm-only {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  &__text{
    @include sm-only {

      margin-bottom: 10px;
      text-align: center;
    }
  }

  &__left{
    max-width: 800px;
    @include sm-only {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }
  }

  &__right{
    max-width: 350px;
  }
}

.ambassador--stats__div{
  display:flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
}

.ambassador--stats__item{
  background-color: white;
  margin:20px;
  display:flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width:250px;
  min-height: 390px;
  border-radius: 20px;
  padding: 10px 30px;
}
.ambassador--stats__title{
  width:250px;
  height: 25px;
  display:flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.ambassador--stats__radial-chart{
  width:250px;
  height: 200px;
  display:flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.ambassador--stats__container{
  margin:20px;
  display:flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.ambassador__legends{
  width:150px !important;
  height: 102px !important;
}
.ambassador__legend{
  width:150px;
  height: 30px;
  border-radius: 10px;
  display:flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin:3px;
  color:white;
}

.ambassador-one{
  background-color: rgb(121, 199, 227);
}
.ambassador-two{
  background-color: rgb(26, 49, 119);
}
.ambassador-three{
  background-color: rgb(18, 147, 154);
}

.ambassador-no-stats{
  width:180px;
  height: 290px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}